import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import ReactMarkdown from 'react-markdown'
import Helmet from 'react-helmet'

import AlternativeLayout from '../layouts/AltertineLayout'
import Classes from '../../styles/classes'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  }
})

export default ({ data }) => {
  console.log(data)
  const { service } = data

  let image

  if (service.image) {
    image = service.image.resolutions.src
  }

  const breadcrumbLinks = [
    <Link
      to={service.permalink}
      title={service.titre}
      className={css(Classes.link)}
    >
      {service.titre}
    </Link>
  ]

  const Produits = data.produits
    ? data.produits.edges.map(({ node }) => {
        return (
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <Link to={node.permalink} title={node.titre}>
              <div className="item">
                <div className="item-icon">
                  <span className={'icon ' + node.icone} />
                </div>
                <div className="item-text">
                  <h3 style={{ display: 'inline', wordBreak: 'break-word' }}>
                    {node.titre}
                  </h3>
                  <p>
                    <ReactMarkdown source={node.description.description} />
                  </p>
                </div>

                <div className="item-link">
                  <span>
                    <i className="fas fa-chevron-right" />
                  </span>
                </div>
              </div>
            </Link>
          </div>
        )
      })
    : []
  return (
    <AlternativeLayout
      title={service.titre}
      subtitle={service.description.description}
      image={image}
      contact
      breadcrumbLinks={breadcrumbLinks}
    >
      <Helmet
        title={service.metaTitle}
        meta={[
          {
            name: 'description',
            content: service.metaDescription
          }
        ]}
      />
      <section className="services section-padding">
        <div className="container">
          <div className="row display-flex">{Produits}</div>
        </div>
      </section>
      <section className="about">
        <div className="container">
          <div className="about-text" />
        </div>
      </section>
    </AlternativeLayout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    service: contentfulServices(id: { eq: $id }) {
      id
      titre
      permalink
      icone
      metaTitle
      metaDescription
      description {
        description
      }
      image {
        resolutions(width: 1600) {
          width
          height
          src
          srcSet
        }
      }
    }
    produits: allContentfulProduits(
      filter: { service: { id: { eq: $id } } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          id
          titre
          permalink
          icone
          description {
            description
          }
        }
      }
    }
  }
`
